<template>
  <div class="container-fluid shadow p-3 pe-5 ps-5 mb-5">
    <div class="row flex-nowrap">
      <div class="col navbar-light p-0 m-0">
        <span class="h3">Applications</span>
        <div class="float-end">
          <display-picture :is-left="true" class="rounded-circle"></display-picture>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <button class="btn btn-primary mb-5" @click="upsertApplication({})" role="button">Add Application</button>
      <DataTable2
          :headers="headers"
          :actions="actions"
          :api="getApplications"
          :fields="['email']"
          :trigger="trigger"
      />
    </div>
  </div>
</template>

<script>

import DataTable2 from 'data-table/src/components/DataTable2'
import {services} from "../service/user-api";
import InsertModalService from "modal/src/services/InsertModal";
import ModalService from "modal/src/services/Modal";
import ToastService from "toast/src/services/toast";
import router from "../router";
import DisplayPicture from 'node-back-client/src/components/DisplayPicture'
export default {
  components: {DataTable2,DisplayPicture},
  name: "Application",
  data() {
    return {
      trigger:{},
      headers : {
        _id:"#",
        name:"Name",
        application_id:"Application ID",
      },
      actions: [
        {
          classNames:"fa fa-pen text-primary",
          callback: (entity) => {
            router.push(`/apps/${entity._id}/${entity.application_id}`);
          }
        },
        {
          classNames:"fa fa-trash text-danger",
          callback: (entity) => {
            ModalService.Show({
              title: "Do you want to remove",
              message : `Remove ${entity.name}`,
            }, async () => {
              await services.application.remove(entity._id);
              ToastService.Notify({
                title:"Success",
                message:"Application has been deleted."
              });
              this.trigger.refresh();
            })
          }
        }
      ]
    }
  },
  methods:{
    getApplications(filter,skip,limit) {
      return services.application.post("fetch", {
        filter,skip,limit
      });
    },
    upsertApplication(entity) {
      InsertModalService.Show({
        title: "Add Application",
        type: {
          name: "text",
          appId: "text"
        },
        model: entity
      },async () => {
        entity.forAll = true;
        await services.application.post("auto/create", entity);
        ToastService.Notify({
          title:"Success",
          message:"Application has been created."
        });
        this.trigger.refresh();
      });
    },
  }
}
</script>

<style scoped>

</style>